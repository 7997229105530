import {find} from "@elements/dom-utils";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as etrustedWidget from '../scripts/etrusted-rating-widget';
etrustedWidget.init();

import * as loadNewsletter from '../scripts/load-newsletter';
loadNewsletter.init();

import * as headerBanner from '../scripts/header-banner';
headerBanner.init();

import * as affix from '@elements/affix';

let affixNavbar = find('.js-affix-navbar');
let options = {mediaQuery: '(max-width: 767px)'};
affix.init({
},{
    base: '.js-affix-navbar',
    placeholder: '.js-affix-navbar__placeholder',
    container: '.js-affix-navbar__container'
});

import * as alertNotificationsExtended from './alert-notifications-extended';
alertNotificationsExtended.init();

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';

import * as autocomplete from './autocomplete';
autocomplete.init();

import * as newsletterBox from './newsletter-box';
newsletterBox.init();

