import {on, find, findAllIn, addClass, removeClass, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-header-banner', function (sidebar) {
        let header = find('.js-header-banner__header');
        let headerHeight = header.offsetHeight;
        let sidebarTopValue = sidebar.offsetTop;

        if (sidebarTopValue !== headerHeight) {
            sidebar.style.top = headerHeight + 'px';
        }
    });
}
