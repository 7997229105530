import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-info-modal', function(element) {
    if(element){
        let modalInstance = element.dataset.instance;
        let infoModal = new Modal(element);

        element.addEventListener('hide.bs.modal', function () {
            sessionStorage.setItem('infoModal','closed');
        });

        let count = sessionStorage.getItem('loadCount') || 0;
        sessionStorage.setItem('loadCount', parseInt(count) + 1);

        if(element.dataset.showcount && sessionStorage.getItem('infoModal') !== 'closed') {

            let sec = Number(element.dataset.showcount) * 1000;
            let t = setTimeout(function(){
                infoModal.show();
                clearTimeout(t);
            }, sec);

        } else if(sessionStorage.getItem('loadCount') > 1 && sessionStorage.getItem('infoModal') !== 'closed') {
            infoModal.show();
        }
    }
});
}